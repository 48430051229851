// extracted by mini-css-extract-plugin
export var alignLeft = "B_pB d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_pC d_fr d_bH d_dx";
export var verticalSolidComponent = "B_tQ";
export var compContent = "B_tK d_r";
export var normal = "B_tR d_Z d_cb";
export var reverse = "B_tS d_Z d_bK";
export var last = "B_tL";
export var empty = "B_tT d_r";
export var empty2 = "B_tV d_bw";
export var borderRight = "B_tW d_b8 d_c1";
export var borderLeft = "B_tX d_b9 d_c1";