// extracted by mini-css-extract-plugin
export var alignLeft = "z_pB d_fp d_bG d_dv";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignRight = "z_pC d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "z_tG";
export var wrap = "z_tH d_bJ d_cb";
export var topPadding = "z_tJ d_cX";
export var compContent = "z_tK d_w";
export var last = "z_tL";
export var box = "z_tM d_cr";
export var boxWrapper = "z_tN d_w d_bz";
export var boxWrapperFirst = "z_tP d_w d_bz";