// extracted by mini-css-extract-plugin
export var alignLeft = "v_pB d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_pC d_bH";
export var textAlignLeft = "v_rW";
export var textAlignCenter = "v_rX";
export var textAlignRight = "v_rY";
export var hoursInnerWrapperAlt = "v_rZ d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "v_r0 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "v_r1 d_dw";
export var titleMargin = "v_r2 d_cw";
export var hoursInnerInnerWrapper = "v_r3 d_cz";