// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pS";
export var articleText = "m_pT d_dv d_cs";
export var header = "m_pV d_Z d_w";
export var headerImageWrapper = "m_pW d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "m_pX d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "m_pY d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "m_pZ d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "m_p0 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "m_mv d_w";
export var dateTag = "m_p1 d_cf d_cr d_dq d_w";
export var icon1 = "m_p2 d_cP";
export var icon2 = "m_p3 d_cP d_cJ";
export var tagBtn = "m_p4 d_b1 d_cD d_dp d_dB";
export var headerText = "m_p5 d_w d_cf d_cs d_bW";
export var center = "m_p6 d_dw";
export var videoIframeStyle = "m_p7 d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "m_p8 d_cs d_Z";
export var articleImageWrapperIcon = "m_p9 d_cs d_Z";
export var articleRow = "m_qb d_bD";
export var quoteWrapper = "m_gQ d_bC d_bP d_cs";
export var quoteBar = "m_qc d_H";
export var quoteText = "m_qd";
export var authorBox = "m_qf d_w";
export var authorRow = "m_qg d_bD d_bW d_cy";
export var separator = "m_qh d_w";
export var line = "m_fk d_w d_fk d_cY d_cy";
export var authorImage = "m_ln d_b7 d_Z d_w d_ct";
export var authorText = "m_qj d_ch d_ct";
export var masonryImageWrapper = "m_qk";
export var bottomSeparator = "m_ql d_w d_cy";
export var linksWrapper = "m_qm d_ch d_dv";
export var comments = "m_qn d_bD d_cy";
export var sharing = "m_qp d_bz d_bD d_bJ";
export var shareText = "m_qq d_w d_dw";
export var icon = "m_qr";
export var customRow = "m_qs d_bD d_Z";
export var text = "m_qt";
export var SubtitleSmall = "m_qv";
export var SubtitleNormal = "m_qw";
export var SubtitleLarge = "m_qx";